<!--登录页-->
<!--登录页-->

<style scoped>
.content {
  background: #fff;
  line-height: 34px;
  width: 1300px;
  text-align: left;
  margin: 20px auto;
}
h1 {
  font-size: 20px;
  text-align: center;
  font-weight: bold;
}

h2 {
  font-size: 16px;
  font-weight: bold;
}
h3,
p {
  text-indent: 2em;
}
h4{
   text-indent: 2em;  
   font-weight: normal;
   margin:5px 0;
}
</style>


<template>
  <div class="content">
    <h1>用户服务协议</h1>
    <h2>第一条 重要提示</h2>
    <p>
      欢迎申请并使用四方云FBT服务平台（以下简称“平台”）所提供的各项服务。请您（以下亦称“用户”）在登录平台前，仔细阅读本协议之全部条款，并确认您已完全理解本协议之规定，包括但不限于知识产权条款、法律适用及争议解决条款等涉及您重大权益及义务的条款，如您对本协议有任何疑问，请立即停止登录或使用平台，并可向北京传世博润科技有限公司客服咨询。
    </p>
    <h2>第二条 协议的接受与变更</h2>
    <p>
      2.1
      在您充分阅读并且理解本协议，并勾选本协议前选项框或点击登录按钮成功登录，您的行为表示您同意并签署了本协议，表明您确定自己具有接受平台服务的权利和行为能力，并自愿接受本协议全部条款的约束，本协议构成您与北京传世博润科技有限公司（以下简称“我们”）及其经营的平台之间具有约束力的法律文件。
    </p>
    <p>
      2.2
      我们会根据国家法律法规变化及运营需要不时的对本协议进行修改，修改后的协议一经公布既有效的代替原协议。用户有义务不时关注并阅读最新版本的协议、声明、规则等内容。如果您不同意本协议的修改，请立即停止访问或使用本软件或取消已经获得的服务；如果您选择在本协议修改并公布后继续访问或使用，则视为您同意接受本协议的修改。
    </p>
    <p>
      2.3
      您与我们签署的本协议所列明的条款，并不能完全涵盖您与我们所有的权利和义务。因此，我们公布的其他声明、规则等均视为本协议之补充协议，为本协议不可分割的组成部分，与本协议具有同等的法律效力。若其他声明、规则等与服务协议存在任何冲突或不一致的，以本协议为准。
    </p>
    <h2>第三条 账户的取得与使用</h2>
    <p>
      3.1
      您需确认，在您开始使用平台前，应当具有中华人民共和国法律规定的与您行为相适应的民事行为能力。若您不具备前述与您行为相适应的民事行为能力，则您及您的监护人应按照法律的规定承担因此而导致的一切后果。
    </p>
    <p>3.2
      您有权选择是否成为平台用户。</p>
    <p>
      3.3
      您选择成为平台用户时，应按照平台客服的提示及要求填写或提供资料、信息，并确保资料、信息的真实性、正确性及完整性。如果您的资料、信息发生变化，应及时修改。
    </p>
    <p>
      3.4
      成为平台用户后将得到一个用户名和密码，用户凭用户名和密码享受平台向其会员用户提供的服务。
    </p>
    <p>
      3.5
      用户将对用户名和密码安全负全部责任，并且用户对以其用户名进行的所有活动和事件负全责。用户有权根据平台规定的程序修改密码。非经平台书面同意，用户名和密码不得擅自转让或者授权他人使用，否则用户应承担由此造成的一切后果。
    </p>
    <p>
      3.6
      用户若发现任何非法使用用户账号或存在安全漏洞的情况，请立即通告本公司。</p>
    <p>
      3.7
      为改善用户体验、完善服务内容，我们将不断努力开发新的产品和服务，并为您不时提供软件更新（这些更新可能会采取软件替换、修改、功能强化、版本升级等形式）。为保证本软件安全性和功能的一致性，我们有权不经向您特别通知而对软件进行更新，或者对软件的部分功能效果进行改变或限制。本软件不保证旧版本软件继续可用及相应的客户服务，请您随时核对并下载最新版本。
    </p>

    <h2>第四条 您的权利和义务</h2>
    <p>4.1 用户有权利拥有自己在平台的用户名和密码并有权利使用自己的用户名和密码随时登录平台。</p>
    <p>
      4.2 用户有权利享受平台提供的互联网技术和信息服务，并有权利在接受平台提供的服务时获得平台的技术支持、咨询等服务。
    </p>
    <p>4.3 用户保证不会利用技术或其他手段破坏及扰乱平台。</p>
    <p>
      4.4 用户应尊重平台及其他第三方的知识产权和其他合法权利，平台保留用户侵犯平台知识产权时终止向该用户提供服务并不退还任何款项的权利。
    </p>
    <p>
      4.5 对由于用户给平台提供的联络方式有误而导致的一切后果，用户应自行承担责任，包括但不限于因用户未能及时收到平台的相关通知而导致的后果和损失。
    </p>
    <p>
      4.6 用户保证其使用平台服务时将遵从国家、地方法律法规、行业惯例和社会公共道德，不会利用平台提供的服务进行存储、发布、传播如下信息和内容：违反国家法律法规政策的任何内容（信息）；违反国家规定的政治宣传和/或新闻信息；涉及国家秘密和/或安全的信息；封建迷信和/或淫秽、色情、下流的信息或教唆犯罪的信息；博彩有奖、赌博游戏；违反国家民族和宗教政策的信息；妨碍互联网运行安全的信息；侵害他人合法权益的信息和/或其他有损于社会秩序、社会治安、公共道德的信息或内容。用户同时承诺不得为他人发布上述不符合国家规定和本服务条款约定的信息内容提供任何便利，包括但不限于设置URL、BANNER链接等。用户承认平台有权在用户违反上述时有权终止向用户提供服务并不与退还任何款项，因用户上述行为给平台造成损失的，用户应予赔偿。
    </p>
    <h2>第五条 平台的权利和义务</h2>
    <p>5.1 平台应根据用户类型向用户提供合格的网络技术和信息服务。</p>
    <p>5.2 平台承诺对用户资料采取对外保密措施，不向第三方披露用户资料，不授权第三方使用用户资料，除非：</p>
    <h4>（1）依据本协议条款或者用户与 平台之间其他服务协议、合同、在线条款等规定可以提供；</h4>
    <h4>（2）依据法律法规的规定应当提供；</h4>
    <h4>（3）行政、司法等有权部门要求本公司提供；</h4>
    <h4>（4）用户同意平台向第三方提供；</h4>
    <h4>（5）平台解决举报事件、提起诉讼而提交的；</h4>
    <h4>（6）平台为防止严重违法行为或涉嫌犯罪行为发生而采取必要合理行动所必须提交的；</h4>
    <h4>
      （7）平台为向用户提供产品、服务、信息而向第三方提供的，包括平台通过第三方的技术及服务向用户提供产品、服务、信息的情况。
    </h4>
    <p>5.3 平台有权使用用户资料。</p>
    <p>
      5.4 平台有权利对用户进行审查并决定是否接受用户成为平台会员或是否与用户进行某一交易。
    </p>
    <p>
      5.5
      平台保留在用户违反国家、地方法律法规规定或违反本注册条款的情况下终止为用户提供服务并终止用户账号的权利，并且在任何情况下，平台对任何间接、偶然、特殊及继起的损害不负责任。
    </p>
    <h2>第六条 免责说明</h2>
    <p>
      6.1 我们作为网络服务的提供者，不保证平台系统能充分满足您的需求，您在接受平台服务的过程中，可能遇到的错误、不作为等事件，我们不承担法律责任。
    </p>
    <p>
      6.2
      您作为平台系统的用户有义务确保在平台系统中录入数据的真实性，我们作为平台应用的提供者，不对您在平台系统中录入数据的真实性承担法律责任。
    </p>
    <p>
      6.3
      基于互联网的特殊性，我们也不能保证您在使用我们提供的服务过程中，服务不中断，我们对服务的及时性、安全性不作保证，不承担非因我们导致的任何责任。我们竭力保证您对本网站进行安全的访问和使用，但我们不保证网站或服务器不含病毒或其他潜在有害因素。
    </p>
    <p>
      6.4
      如果我们发现或收到他人举报或投诉您在使用过程中违反任何法律法规、公序良俗或本协议的规定，我们有权要求不经通知随时对账号进行处理，并视情节对违规账号处以包括但不限于警告、限制或禁止使用部分或全部产品及服务，账号封禁直至注销。
    </p>
    <p>
      6.5 您应从本公司官网或其他拥有平台授权的第三方获得安装程序。如果您从未经平台授权的第三方获得平台安装程序或名称相同的安装程序，我们及平台无法保证软件能够正常使用，并对因此给您造成损失不予负责。
    </p>
    <p>
      6.6
      您理解并同意，因违法法律法规、公序良俗或本协议的规定，导致或产生第三方主张的任何索赔、要求或损失，导致或产生第三方主张的任何索赔、要求或损失，您应当独立承担全部责任。我们或平台因此遭受损失的，您也应当一并赔偿。
    </p>
    <h2>第七条 知识产权归属与授权</h2>
    <p>
      平台的一切商标权、专利权等知识产权和商业秘密，以及与平台相关的所有信息内容。包括但不限于图标、图片、色彩、界面设计、版面框架、有关数据等均受中华人民共和国著作权法、商标法、专利发、反不正当竞争法和相应的国际条约以及其他知识产权法律法规的保护，除涉及第三方授权的知识产权外，平台享有上述所有知识产权，未经平台书面同意，用户不得为任何营利或非营利性的目的自行实施、利用转让或许可任何第三方实施、利用、转让上述知识产权，平台保留追究上述未经许可的法律责任的权利。您对所使用的软件有其专属性使用权，但不得自行或许可任何第三方复制、修改、出售或衍生产品。
    </p>
    <h2>第八条 服务的终止</h2>
    <p>8.1 用户有权随时申请终止其会员资格。</p>
    <p>
      8.2 平台有权根据实际情况决定取消为用户提供服务，视情况确定是否退还用户为该服务所交纳款项的剩余部分，本公司不承担其他任何责任。
    </p>

    <h2>第九条 法律适用、管辖和其他</h2>
    <p>
      9.1
      本协议的生效、履行、解释及争议解决均适用中华人民共和国法律。您因使用平台而产生或与之相关的一切争议，权利主张或其他思想，均适用中华人民共和国法律。
    </p>
    <p>
      9.2 您与我们及我们经营的平台发生的一切争议，应友好协商，如协商不成的，应提交北京市朝阳区人民法院管辖。
    </p>
    <p>
      9.3
      我们可能根据运营需要，不时的发布针对包含您在内的用户的相关协议，并可能将该相关协议作为对本协议的补充或修改，使其成为本协议的一部分。请您及时关注并阅读相关协议。
    </p>
    <p>9.4 本协议部分条款被视为废止、无效或不可执行，本协议其余条款仍应有效并具有约束力与可执行性。</p>
    <p>9.5 我们未行使或执行本服务协议任何权利或规定，不构成对前述权利或权益之放弃。</p>
    <p>
      【审慎提醒】如您点击“同意”、勾选相关接受提示。则本协议立即生效，并构成您和北京传世博润科技有限公司及其经营的平台之间有约束力的法律文件。
    </p>
  </div>
</template>

<script>
export default {};
</script>
